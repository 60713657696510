<template>
  <div>
    <ag-table
      :data-source-api="getAll"
      :grid-options="gridOptions"
      :permissions="permissions"
      :is-entities-response="false"
    />
  </div>
</template>

<script>
  import {SharedRepositoryFactory} from "@/app/shared/shared-repositories/sharedRepositoryFactory";
  import AgTable from '../../shared/shared-components/ag-grid/AgTableServerSide.vue';
  import TmPopup from "@/app/shared/shared-components/TmPopup";
  import TmInput from "@/app/shared/shared-components/TmInput";

  const telegramContactsRepository = SharedRepositoryFactory.get('telegramContactsRepository');
  export default {
    name: "TelegramContacts",
    components: {
      AgTable,
      TmPopup,
      TmInput
    },
    data() {
      return {
        gridApi: null,
        dialogTitle: '',
        district: {
          id: '',
          firstName: '',
          lastName: '',
          phoneNumber:'',
          telegramChatId:'',
          telegramUsername:'',
        },
        gridOptions: null,
        permissions: {}
      };
    },
    methods: {
      getAll(query) {
        return telegramContactsRepository.fetchAllContacts(query).then((response => response));
      },
      createColDefs() {
        let self = this;
        return [
          {
            headerName: 'المعرف',
            field: 'id',
            filter: 'agNumberColumnFilter'
          },
          {
            headerName: 'الاسم',
            field: 'firstName',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'الكنية',
            field: 'lastName',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'رقم الموبايل',
            field: 'phoneNumber',
            filter: 'agTextColumnFilter',
            width: 250
          },
          {
            headerName: 'اسم المستخدم',
            field: 'telegramUsername',
            filter: 'agTextColumnFilter',
            width: 250
          },
          {
            headerName: 'معرف التلغرام',
            field: 'telegramChatId',
            filter: 'agTextColumnFilter',
          },
        ];
      },
    },
    created() {
      this.gridOptions = {};
      this.gridOptions.columnDefs = this.createColDefs();
    },
    mounted() {
      this.gridApi = this.gridOptions.api;
    }
  };
</script>
